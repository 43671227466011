import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_8_CHAR,
  PASSWORD_REGEX,
  CONFIRM_PASSWORD_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_MATCH,
  TITLE_REQUIRED,
  CONTENT_REQUIRED,
  PAGETYPE_REQUIRED,
  REPLY_REQUIRED,
  CATEGORY_REQUIRED,

  LEVEL_REQUIRED,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  MAXIMUM_255_CHAR,
  EMAIL_REQUIRED,
  VALID_EMAIL,
  ROLE_ID_REQUIRED,
  LANG_KEY_REQUIRED,
  PASSWORD_REQUIRED,
  DIRECTORATE_ENGLISH_REQUIRED,
  DIRECTORATE_ARABIC_REQUIRED,
  GOVERNORATE_ENGLISH_REQUIRED,
  GOVERNORATE_ARABIC_REQUIRED,
  VILLAGE_ENGLISH_REQUIRED,
  VILLAGE_ARABIC_REQUIRED,
  AMENITY_ENGLISH_REQUIRED,
  AMENITY_ARABIC_REQUIRED,
  DESCRIPTION_REQUIRED,
  QUESTION_REQUIRED,
  PLANNAME_REQUIRED,
  NUMBER_OF_ACTIVE_PROPERTY_REQUIRED,
  VALIDATION_REQUIRED,
  NUMBER_OF_ACTIVE_AGENT_REQUIRED,
  NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED,
  NUMBER_OF_3D_VIEW_REQUIRED,
  PRICE_REQUIRED,
  AGENCY_REQUIRED,
  NO_NEGATIVE_VALUE,
  CAPITAL_ENGLISH_REQUIRED,
  CAPITAL_ARABIC_REQUIRED,
  GOVERNORATE_IMAGE_REQUIRED,
  ICON_REQUIRED,
  ONLY_ENGLISH_CHARACTERS,
  ONLY_ARABIC_CHARACTERS,
  ADDITIONAL_COST_REQUIRED,
  MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY,
  MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW,
  MAXIMUM_9999,
  MAX_ONE_CR,
  MAXIMUM_700_CHAR,
  META_TITLE_REQUIRED,
  KEYWORDS_REQUIRED,
  META_DESCRIPTION_REQUIRED,
  UNSUPPORTED_FORMAT,
  AUTHOR_REQUIRED,
  AT_LEAST_ONE_KEYWORD_REQUIRED,
  MAX_3D_VIEW_REQUIRED,
  MAX_FEATURED_PROPERTY_REQUIRED


} from "./ValidationErrors";



// https://chat.openai.com  and www.chat.openai.com
const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/;

// const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[!$%^()_+<>#*@`~&=\|'";:/?.,])\S+$/);
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!$}{%^()_+<>#*@`~&=\|'";:/?.,])[A-Za-z\d!$}{%^()_+<>#*@`~&=\|'";:/?.,]{8,14}$/;

const englishRegex = /^[^\u0600-\u06FF]*$/
const arabicRegex = /^[\u0600-\u06FF0-9\s\W]*$/;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];


// ADD AGENT SCHEMA

export const addSystemUserSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED),
  lastName: yup.string().required(LAST_NAME_REQUIRED),
  email: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED)
    .max(300, MAXIMUM_255_CHAR),
  roleId: yup.string().required(ROLE_ID_REQUIRED),
  langKey: yup.string().required(LANG_KEY_REQUIRED),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirmPassword: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
  about_en: yup.string().nullable().matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  about_ar: yup.string().nullable().matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

// EDIT PLATFORM USER SCHEMA

export const editSystemUserSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED),
  lastName: yup.string().required(LAST_NAME_REQUIRED),
  about_en: yup.string().nullable().matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  about_ar: yup.string().nullable().matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

// CHANGE PASSWORD FOR EDIT PLATFORM USER

export const changePasswordSystemUserchema = yup.object().shape({
  password: yup.string().required().min(8, MINIMUM_8_CHAR).max(14, MAXIMUM_30_CHAR).matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password")], CONFIRM_PASSWORD_MATCH),
})

// AGENCY REJECT FORM SCHEMA
export const rejectFormSchema = yup.object().shape({
  reason: yup.string().required().max(255, MAXIMUM_255_CHAR)
})
// AGENCY EDIT FORM SCHEMA
export const agencyEditSchema = yup.object().shape({
  agencyName: yup.string().required().max(255, MAXIMUM_255_CHAR),
  contactName: yup.string().required().max(255, MAXIMUM_255_CHAR),
  agencyEmail: yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED).max(300, MAXIMUM_255_CHAR),
  agencyAddress: yup.string().required().max(255, MAXIMUM_255_CHAR),
})


// Profile
export const ProfileSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR)
    .matches(
      passwordRegex,
      PASSWORD_REGEX
    ),
  new_password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR)
    .matches(
      passwordRegex,
      PASSWORD_REGEX
    ),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("new_password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Add CMS
export const AddCmsSchema = yup.object().shape({
  title: yup.string().required(TITLE_REQUIRED),
  page_type: yup.string().required(PAGETYPE_REQUIRED),
  content_en: yup.string().required(CONTENT_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  content_ar: yup.string().required(CONTENT_REQUIRED),

});

// Add CMS
export const EditCmsSchema = yup.object().shape({
  content_en: yup.string().required(CONTENT_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  metaTitle_en: yup.string().required(META_TITLE_REQUIRED),
  metaTitle_ar: yup.string().required(META_TITLE_REQUIRED),
  keywords_en: yup.array().min(1, KEYWORDS_REQUIRED).required(KEYWORDS_REQUIRED),
  keywords_ar: yup.array().min(1, KEYWORDS_REQUIRED).required(KEYWORDS_REQUIRED),
  metaDescription_en: yup.string().required(META_DESCRIPTION_REQUIRED),
  metaDescription_ar: yup.string().required(CONTENT_REQUIRED),
  content_ar: yup.string().required(CONTENT_REQUIRED)
});

export const addFaqValidationSchema = yup.object().shape({
  question_en: yup.string().required(QUESTION_REQUIRED).max(700, MAXIMUM_700_CHAR).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  question_ar: yup.string().required(QUESTION_REQUIRED).max(700, MAXIMUM_700_CHAR),
  description_en: yup.string().required(DESCRIPTION_REQUIRED).max(700, MAXIMUM_700_CHAR).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  description_ar: yup.string().required(DESCRIPTION_REQUIRED).max(700, MAXIMUM_700_CHAR),
  category: yup.string().required(CATEGORY_REQUIRED),
})

// Add Reply in contact-us
export const replyContactusSchema = yup.object().shape({
  reply: yup.string().required(REPLY_REQUIRED),
});

// Add Artical
export const AddArticalSchema = yup.object().shape({
  category_id: yup.string().required(CATEGORY_REQUIRED),
  level: yup.string().required(LEVEL_REQUIRED),
  title: yup.string().required(TITLE_REQUIRED),
  image: yup.string().optional(),
  content: yup.string().required(CONTENT_REQUIRED),
});

export const updateDirectorateSchema = yup.object().shape({
  directorate_en: yup.string().required(DIRECTORATE_ENGLISH_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  directorate_ar: yup.string().required(DIRECTORATE_ARABIC_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS)
})

export const updateGovernorateSchema = yup.object().shape({
  image: yup.string().when('imageUrl', {
    is: (imageUrl) => !imageUrl || imageUrl.trim() === '', // Check if id is empty or has no value
    then: yup.string().nullable().required(GOVERNORATE_IMAGE_REQUIRED),
    otherwise: yup.string().nullable(),
  }),
  governorate_en: yup.string().required(GOVERNORATE_ENGLISH_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  governorate_ar: yup.string().required(GOVERNORATE_ARABIC_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  capital_en: yup.string().required(CAPITAL_ENGLISH_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  capital_ar: yup.string().required(CAPITAL_ARABIC_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

export const updateVillageSchema = yup.object().shape({
  village_en: yup.string().required(VILLAGE_ENGLISH_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  village_ar: yup.string().required(VILLAGE_ARABIC_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

// AMENITY SCHEMA
export const updateAmenitySchema = yup.object().shape({
  amenity_en: yup.string().required(AMENITY_ENGLISH_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  amenity_ar: yup.string().required(AMENITY_ARABIC_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

// PROPERTY TYPE SCHEMA 
export const propertyType = yup.object().shape({
  title_en: yup.string().required(TITLE_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  title_ar: yup.string().required(TITLE_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  category: yup.string().required(CATEGORY_REQUIRED),
  iconClassName: yup.string().required(ICON_REQUIRED)
})

export const updatePackageSchema = yup.object().shape({
  planName: yup.string().required(PLANNAME_REQUIRED),
  // MONTHLY PLAN
  monthly_price: yup.number().required(PRICE_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  monthly_validityType: yup.string().optional(VALIDATION_REQUIRED),
  monthly_noOfActiveAgent: yup.number().required(NUMBER_OF_ACTIVE_AGENT_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  monthly_noOfActiveProperty: yup.number().required(NUMBER_OF_ACTIVE_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  monthly_noOfActiveFeaturedProperty: yup.number().required(NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  monthly_noOf3dViewUpload: yup.number().required(NUMBER_OF_3D_VIEW_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  // monthly_dashboard: yup.string().required(DASHBOARD_REQU  IRED),
  // YEARLY PLAN
  yearly_price: yup.number().required(PRICE_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  yearly_validityType: yup.string().optional(VALIDATION_REQUIRED),
  yearly_noOfActiveAgent: yup.number().required(NUMBER_OF_ACTIVE_AGENT_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  yearly_noOfActiveProperty: yup.number().required(NUMBER_OF_ACTIVE_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  yearly_noOfActiveFeaturedProperty: yup.number().required(NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  yearly_noOf3dViewUpload: yup.number().required(NUMBER_OF_3D_VIEW_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  // yearly_dashboard: yup.string().required(DASHBOARD_REQUIRED),
  // maxNoOfActiveFeaturedProperty:yup.number().nullable().min(1, NO_NEGATIVE_VALUE),
  // maxNoOf3dViewUpload: yup.number().nullable().min(1, NO_NEGATIVE_VALUE),
  // additionalCostPer3dViewUpload: yup.number().nullable().min(1, NO_NEGATIVE_VALUE),
  // additionalCostPerFeaturedProperty: yup.number().nullable().min(1, NO_NEGATIVE_VALUE),
  monthly_maxFeaturedProperty: yup.number().required(MAX_FEATURED_PROPERTY_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.monthly_noOfActiveFeaturedProperty || 0)
      );
    }),

  monthly_max3DViewUpload: yup.number().required(MAX_3D_VIEW_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.monthly_noOf3dViewUpload || 0)
      );
    }),
  monthly_costPer3dView: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  monthly_costPerFeaturedProperty: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  // YEARLY
  yearly_maxFeaturedProperty: yup.number().required(MAX_FEATURED_PROPERTY_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.yearly_noOfActiveFeaturedProperty || 0)
      );
    }),

  yearly_max3DViewUpload: yup.number().required(MAX_3D_VIEW_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.yearly_noOf3dViewUpload || 0)
      );
    }),
  yearly_costPer3dView: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  yearly_costPerFeaturedProperty: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
})
// ADD CUSTOM PACKAGE SCHEMA
export const addCustomPackageSchema = yup.object().shape({
  agencyIds: yup.array().min(1, AGENCY_REQUIRED).required(AGENCY_REQUIRED),
  planName: yup.string().required(PLANNAME_REQUIRED),
  // MONTHLY PLAN
  monthly_price: yup.number().required(PRICE_REQUIRED).min(1, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  monthly_validityType: yup.string().optional(VALIDATION_REQUIRED),
  monthly_noOfActiveAgent: yup.string().required(NUMBER_OF_ACTIVE_AGENT_REQUIRED),
  monthly_noOfActiveProperty: yup.string().required(NUMBER_OF_ACTIVE_PROPERTY_REQUIRED),
  monthly_noOfActiveFeaturedProperty: yup.number().required(NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  monthly_noOf3dViewUpload: yup.number().required(NUMBER_OF_3D_VIEW_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  // monthly_dashboard: yup.string().required(DASHBOARD_REQUIRED),
  // YEARLY PLAN
  yearly_price: yup.number().required(PRICE_REQUIRED).min(1, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  yearly_validityType: yup.string().optional(VALIDATION_REQUIRED),
  yearly_noOfActiveAgent: yup.number().required(NUMBER_OF_ACTIVE_AGENT_REQUIRED),
  yearly_noOfActiveProperty: yup.number().required(NUMBER_OF_ACTIVE_PROPERTY_REQUIRED),
  yearly_noOfActiveFeaturedProperty: yup.number().required(NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED).max(9999, MAXIMUM_9999),
  yearly_noOf3dViewUpload: yup.number().required(NUMBER_OF_3D_VIEW_REQUIRED).max(9999, MAXIMUM_9999),
  // yearly_dashboard: yup.string().required(DASHBOARD_REQUIRED),
  monthly_maxFeaturedProperty: yup.number().required(MAX_FEATURED_PROPERTY_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.monthly_noOfActiveFeaturedProperty || 0)
      );
    }),

  monthly_max3DViewUpload: yup.number().required(MAX_3D_VIEW_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.monthly_noOf3dViewUpload || 0)
      );
    }),
  monthly_costPer3dView: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  monthly_costPerFeaturedProperty: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  // YEARLY
  yearly_maxFeaturedProperty: yup.number().required(MAX_FEATURED_PROPERTY_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.yearly_noOfActiveFeaturedProperty || 0)
      );
    }),

  yearly_max3DViewUpload: yup.number().required(MAX_3D_VIEW_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.yearly_noOf3dViewUpload || 0)
      );
    }),
  yearly_costPer3dView: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  yearly_costPerFeaturedProperty: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
})
// EDIT CUSTOM PACKAGE SCHEMA
export const editCustomPackageSchema = yup.object().shape({
  agencyIds: yup.array().min(1, AGENCY_REQUIRED).required(AGENCY_REQUIRED),
  planName: yup.string().required(PLANNAME_REQUIRED),
  // MONTHLY PLAN
  monthly_price: yup.number().required(PRICE_REQUIRED).min(1, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  monthly_validityType: yup.string().optional(VALIDATION_REQUIRED),
  monthly_noOfActiveAgent: yup.number().required(NUMBER_OF_ACTIVE_AGENT_REQUIRED),
  monthly_noOfActiveProperty: yup.number().required(NUMBER_OF_ACTIVE_PROPERTY_REQUIRED),
  monthly_noOfActiveFeaturedProperty: yup.number().required(NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  monthly_noOf3dViewUpload: yup.number().required(NUMBER_OF_3D_VIEW_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  // monthly_dashboard: yup.string().required(DASHBOARD_REQUIRED),
  // YEARLY PLAN
  yearly_price: yup.number().required(PRICE_REQUIRED).min(1, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  yearly_validityType: yup.string().optional(VALIDATION_REQUIRED),
  yearly_noOfActiveAgent: yup.number().required(NUMBER_OF_ACTIVE_AGENT_REQUIRED),
  yearly_noOfActiveProperty: yup.number().required(NUMBER_OF_ACTIVE_PROPERTY_REQUIRED),
  yearly_noOfActiveFeaturedProperty: yup.number().required(NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  yearly_noOf3dViewUpload: yup.number().required(NUMBER_OF_3D_VIEW_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(9999, MAXIMUM_9999),
  // yearly_dashboard: yup.string().required(DASHBOARD_REQUIRED),
  monthly_maxFeaturedProperty: yup.number().required(MAX_FEATURED_PROPERTY_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.monthly_noOfActiveFeaturedProperty || 0)
      );
    }),

  monthly_max3DViewUpload: yup.number().required(MAX_3D_VIEW_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.monthly_noOf3dViewUpload || 0)
      );
    }),
  monthly_costPer3dView: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  monthly_costPerFeaturedProperty: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  // YEARLY
  yearly_maxFeaturedProperty: yup.number().required(MAX_FEATURED_PROPERTY_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.yearly_noOfActiveFeaturedProperty || 0)
      );
    }),

  yearly_max3DViewUpload: yup.number().required(MAX_3D_VIEW_REQUIRED).nullable().min(0, NO_NEGATIVE_VALUE)
    .test('is-not-less-than-active', MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW, function (value) {
      return value === undefined || value === null || value === '' || (
        value >= (this.parent.yearly_noOf3dViewUpload || 0)
      );
    }),
  yearly_costPer3dView: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
  yearly_costPerFeaturedProperty: yup.number().required(ADDITIONAL_COST_REQUIRED).min(0, NO_NEGATIVE_VALUE).max(10000000, MAX_ONE_CR),
})

// Push notification
export const pushNotificationSchema = yup.object().shape({
  title: yup.string().required(TITLE_REQUIRED),
  description: yup.string().required(DESCRIPTION_REQUIRED),

});

export const blogValidationSchema = yup.object().shape({
  // ADD BLOGS
  image: yup.mixed().test('fileFormat', UNSUPPORTED_FORMAT, value => !value || SUPPORTED_FORMATS.includes(value.type)),
  title_en: yup.string().required(TITLE_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  title_ar: yup.string().required(TITLE_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  author_en: yup.string().required(AUTHOR_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  author_ar: yup.string().required(AUTHOR_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  metaTitle_en: yup.string().required(META_TITLE_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  metaTitle_ar: yup.string().required(META_TITLE_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  metaDescription_en: yup.string().required(META_DESCRIPTION_REQUIRED).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  metaDescription_ar: yup.string().required(META_DESCRIPTION_REQUIRED).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  // keywords_en: yup.array().min(1, KEYWORDS_REQUIRED).required(AT_LEAST_ONE_KEYWORD_REQUIRED),
  // keywords_ar: yup.array().min(1, KEYWORDS_REQUIRED).required(AT_LEAST_ONE_KEYWORD_REQUIRED),
  content_en: yup.string().required(CONTENT_REQUIRED),
  content_ar: yup.string().required(CONTENT_REQUIRED)
})

