import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Card, Stack, Row, Col } from 'react-bootstrap';
import { MdSupervisorAccount, MdClear } from "react-icons/md";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MatDataGridWithPagination } from '../../../components/common/dataGrid/DataGridWithPagination';
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { getWebsiteUsersListAPI, updateWebsiteUsersAPI } from '../../../services/userService';
import { toast } from 'react-toastify';
import LoaderMu from '../../../components/common/LoaderMu';
import { Switch } from 'antd';
import { nameBasedRoutes } from '../routes';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../../components/common/NotAuthorized';

function Listing() {


  // MANAGING USER PERMISSIONS HERE

  const roleDetails = useSelector((state) => state?.rolesData?.masterPermissionResponseDTOList)

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [authPermissions, setAuthPermissions] = useState(null)

  useEffect(() => {

    if (roleDetails) {
      const permissions = roleDetails.find((detail) => {
        return detail.childPermissions.find((childPerm) => (
          childPerm?.key === "platform_users_management_list" && childPerm?.status === true
        ))
      })
      if (permissions) {
        setIsAuthorized(true)
        // Create an object from childPermissions
        const permissionsObject = {};
        permissions?.childPermissions.forEach(permission => {
          permissionsObject[permission.title.toLowerCase()] = permission.status;
        });
        setAuthPermissions(permissionsObject)
      } else {
        setIsAuthorized(false)
      }
    } else {
      setIsAuthorized(true)
    }

  }, [roleDetails])

  const location = useLocation();
  const params = new URLSearchParams(location?.search)
  const search = params.get("search") ?? ''
  const [currentPage, setCurrentPage] = useState(params.get("page") ?? 0)

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  let [usersList, setUsersList] = useState()
  const [searchValue, setSearchValue] = useState(search ?? '');
  // const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalResults, setTotalResults] = useState(0);
  const [sortingData, setSortingData] = useState({});
  const [mutation, setMutation] = useState(true);

  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchValue(value);
    if (value !== "") {
      navigate(`${nameBasedRoutes?.users?.path}?page=${currentPage}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedRoutes?.users?.path}?page=${currentPage}`)
    }
  };

  // THIS IS FOR PAGE CHANGE
  const handlePageChange = (value) => {
    setCurrentPage(value?.page)
    setPageSize(value?.pageSize)
    navigate(`${nameBasedRoutes?.users?.path}?page=${value?.page}${search !== '' ? '&search=' + search : ''}`)
    // navigate(`${nameBasedRoutes?.users?.path}?page=${value?.page}`)
  }

  // HANDLE SORT MODAL CHANGE
  const handleSortModelChange = (data) => {
    setSortingData(data?.length > 0 ? data[0] : {})
  }

  // CLEAR SEARCH INPUT BOX
  const handleClear = () => {
    setSearchValue("");
    if (search !== "") {
      navigate(`${nameBasedRoutes?.users?.path}?page=${currentPage}`)
    }
  };

  // COLUMNS FOR DATA TABLE
  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + ((currentPage * pageSize))
    },
    {
      field: "firstName",
      headerName: "First Name",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      sortable: true,
      // renderCell: (props) => {
      //   return  <span>{props?.row?.title?.en}</span>
      // }
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: false,
      sortable: true,
      flex: 0.6,
      minWidth: 150,

    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      sortable: true,
      flex: 0.6,
      minWidth: 150,

    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: false,
      flex: 0.6,
      minWidth: 150,
      renderCell: (props) => {
        return <Switch checked={props?.row?.status == 'ENABLED' ? true : false} checkedChildren={props?.row?.status} unCheckedChildren={props?.row?.status}
          className='fw-bold' onChange={(value) => { statusToggle(value == true ? 'ENABLED' : 'DISABLED', props?.row?.id) }} />
      }
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId={nameBasedRoutes?.users?.path}
            isViewEnable={authPermissions === null ? true  : authPermissions?.detail}
            isEditEnable={false}
            redirectID={props.row.id}
            rowData={props.row}
            pageNumber={currentPage}
            searchedValue={searchValue}
          />
        );
      },
    },]

  // GET PLATFORM USERS DATA LIST FOR TABLE
  const fetchUsersData = () => {
    setLoading(true)
    // ?search=&page=0&size=10&sort=createdDate,DESC
    const params = {
      page: currentPage ?? 0,
      size: pageSize ?? 10,
      search: searchValue ?? '',

    }
    if (sortingData && Object.keys(sortingData).length !== 0) {
      params["sort"] = `${sortingData?.field},${sortingData?.sort?.toUpperCase()}`
    }
    getWebsiteUsersListAPI(params).then(response => {
      setLoading(false)
      setTotalResults(response?.headers["x-total-count"])
      //console.log('response',response)
      setUsersList(response?.data)
      // console.log('response', usersList)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })
  }

  // UPDATE STATUS
  const statusToggle = (value, id) => {
    setLoading(true)
    const formData = {
      id: id,
      status: value
    }
    // CALLING API FOR CREATE PLATFORM USER
    updateWebsiteUsersAPI(formData).then(response => {
      setLoading(false)
      //fetchUsersData()
      toast.success(response?.message ?? 'Status updated!')
      setMutation(!mutation)
    }).catch((error) => {
      setLoading(false)
       if(error?.status == 500){
                toast.error('Unauthorized or server issue. Please refresh or try later. ')
            }else{
                 toast.error(
          error?.error_description
            ? error?.error_description
            : error?.details ? error?.details : error?.message
        );
            }
    })

  }

  useEffect(() => {
    // CALLING FUNCTION FOR FETCH DATA
    if (isAuthorized) {
      fetchUsersData()
    } else {
      setLoading(false)
    }
  }, [mutation, currentPage, pageSize, searchValue, sortingData, isAuthorized])

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        {
          !isAuthorized && !loading ?
            <NotAuthorized /> :
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdSupervisorAccount size={40} />
                    </div>
                    Platform Users List
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <Row className="justify-content-end">
                    <Col sm={5} lg={2} className="mb-3 pb-1">
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => {
                          handleValueChange(e?.target?.value);
                        }}
                      />
                      <MdClear
                        className="clear-postion"
                        onClick={handleClear}
                      ></MdClear>
                    </Col>
                  </Row>
                </div>
                {/* RENDERING DATA TABLE COMPONENT */}
                <MatDataGridWithPagination
                  controlId={"id"}
                  columns={columns}
                  data={usersList ?? []}
                  onPaginationModelChange={handlePageChange}
                  pageSize={pageSize}
                  totalResults={totalResults}
                  currentPage={currentPage}
                  handleSortModelChange={handleSortModelChange}
                // totalPages={totalPages}
                // rowsPerPage={rowPerPage}
                // sortModel={sortColumn ? [
                //   {
                //       "field": sortColumn,
                //       "sort": sortDirection
                //   }
                // ] : []}
                />
              </div>
            </Card>}
      </div>
    </div>
  )
}

export default Listing
