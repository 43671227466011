  import { FaLock,FaQuestionCircle } from "react-icons/fa";
  import {MdDashboard,MdInsertDriveFile,MdOutlineReportGmailerrorred} from "react-icons/md";
  import { PiUsersFill } from "react-icons/pi";
  import { IoIosBusiness, IoIosPaperPlane } from "react-icons/io";
  import { LuBuilding2 } from "react-icons/lu";
  export const NavItems = [
    {
      id: 1,
      menuName: "Dashboard",
      menuIcon: <MdDashboard size={24} />,
      key : "dashboard",
      path: "/app/dashboard",
    },
    {
      id:17,
      menuName: "Reports",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      key:"reports",
      submenu: [
        {
          id: 21,
          menuName: 'System User',
          path: '/app/report/system-user',
          key : "reports_system_user"
        },
        {
          id: 22,
          menuName: 'Agency',
          path: '/app/report/agency',
          key:"reports_agency"
        },
        {
          id: 23,
          menuName: 'Agent',
          path: '/app/report/agent',
          key : "reports_agent"
        },
        {
          id: 24,
          menuName: 'Platform User',
          path: '/app/report/platform-user',
          key : "reports_platform_user"
        },
        {
          id: 25,
          menuName: 'Property',
          path: '/app/report/property',
          key : "reports_property"
        },
        {
          id: 26,
          menuName: 'Plan',
          path: '/app/report/plan',
          key : "reports_plan"
        }
      ]
    },
    {
      id : 2,
      menuName : "Manage Roles Permission",
      menuIcon:<FaLock size={20}/>,
      key : "roles_and_rights_management",
      path : "/app/roles"
    },
    {
      id : 3,
      menuName : "Manage System Users",
      menuIcon:<PiUsersFill size={20}/>,
      key : "user_management",
      path : "/app/users"
    },
    {
      id: 9,
      menuName: "Manage Agency",
      key : "agency",
      menuIcon: <IoIosBusiness size={24} />,
      path: "/app/agency"
    },
    {
      id : 10,
      menuName : "Manage Platform Users",
      menuIcon:<PiUsersFill size={20}/>,
      key : "platform_users_management",
      path : "/app/platform-users"
    },
    {
      id: 4,
      menuName: "Governorate",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      key : "governorate",
      path: "/app/governorate"
    },
    {
      id: 5,
      menuName: "Directorate",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      key : "directorate",
      path: "/app/directorate"
    },
    {
      id: 6,
      menuName: "Village",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      key : "village",
      path: "/app/village"
    },
    {
      id: 7,
      menuName: "Amenity",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      key  : "amenity",
      path: "/app/amenity"
    },
    {
      id: 8,
      menuName: "Property Type",
      menuIcon: <LuBuilding2 size={20} />,
      key : "property_type",
      path: "/app/property-type"
    },
   
    {
      id: 11,
      menuName: "Package Management",
      menuIcon: <IoIosPaperPlane size={24} />,
      key : "subscription_management",
      path: "/app/package"
    },
    {
      id: 12,
      menuName: "Custom Packages",
      key : "subscription_management",
      menuIcon: <IoIosPaperPlane size={24} />,
      path: "/app/custom-package"
    },
    {
      id: 13,
      menuName: "CMS Pages",
      menuIcon: <MdInsertDriveFile size={20} />,
      key : "content_management",
      // menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      path: "/app/cms"
    },
    {
      id: 14,
      menuName: "Faq Pages",
      menuIcon: <FaQuestionCircle size={20} />,
      key : "faq",
      path: "/app/faq"
    },
    {
      id: 15,      
      menuName: "Complaints",
      menuIcon: <MdOutlineReportGmailerrorred size={20} />,
      key : "complaints",
      path: "/app/complaint"
    },
    {
      id: 16,      
      menuName: "Blogs",
      menuIcon: <MdOutlineReportGmailerrorred size={20} />,
      key : "blog_post",
      path: "/app/blogs"
    }
  ]
  
