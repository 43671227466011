export const USER_NAME_REQUIRED = "Username is required.";
export const EMAIL_REQUIRED = "Email address is required.";
export const CURRENT_PASSWORD_REQUIRED = "Current password is required.";
export const NEW_PASSWORD_REQUIRED = "New password is required.";
export const PASSWORD_REQUIRED = "Password is required.";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm password is required.";
export const CONFIRM_PASSWORD_MATCH = "The confirm password must match the new password";
export const MINIMUM_8_CHAR = "Must be a minimum of 8 characters.";
export const MAXIMUM_30_CHAR = "Can not be a maximum than 14 characters.";
export const PASSWORD_REGEX = "Must includes at least 1 capital letter, 1 lowercase letter, 1 special character,1 number and no blank space.";
export const PASSWORD_MUST_MATCH = "Confirm password must match with password.";
export const VALID_EMAIL = "Email must be a valid email.";
export const SITE_CODE_REQUIRED = "Site code is required.";
export const SITE_NAME_REQUIRED = "Site name is required.";
export const CONTACT_FIRST_NAME_REQUIRED = "Person first name is required.";
export const CONTACT_LAST_NAME_REQUIRED = "Person last name is required.";
export const PHONE_NO_REQUIRED = "Phone no is required.";
export const CHAR_MAX_10 = "Can exeed upto maximum 10 characters.";
export const CHAR_MAX_20 = "Can exeed upto maximum 20 characters.";
export const CHAR_MAX_50 = "Can exeed upto maximum 50 characters.";
export const CHAR_MAX_300 = "Can exeed upto maximum 300 characters.";
export const PHONE_NUM_LENGTH = "Phone no should have 10 characters.";
export const EMPLOYEE_CODE_REQUIRED = "Employee code is required.";
export const SITES_CODE_REQUIRED = "Sites selection is required.";
export const REQUIRED = "Field is Required.";
export const NUMERIC_10 = "Should be less than 10";
export const NUMERIC_PERCENTAGE = "Should be valid percentage";
export const CAPTCH_INVALID = "Please Enter Captcha";
export const ONBOARDING_QUESTION_REQUIRED = "Question is required.";
export const ANSWER_TYPE_REQUIRED = "Answer type is required.";
export const WEIGHTAGE_REQUIRED = "Weightage is required.";
export const TITLE_REQUIRED = "Page title is required.";
export const CONTENT_REQUIRED = "Description is required.";
export const PAGE_TITLE_REQUIRED ="Page title is required";
export const META_TITLE_REQUIRED="Meta title is required";
export const KEYWORDS_REQUIRED="Keywords are required";
export const AT_LEAST_ONE_KEYWORD_REQUIRED = "At least one keyword is required.";
export const META_DESCRIPTION_REQUIRED = "Meta description is required";
export const PAGETYPE_REQUIRED = "Page type is required.";
export const REPLY_REQUIRED = "Reply is required.";
export const CATEGORY_REQUIRED = "Sub-Category is required.";
export const CATEGORY_TYPE_REQUIRED = "Category type is required.";
export const LEVEL_REQUIRED = "Level is required.";
export const URL_REQUIRED = "Url is required.";
export const ICON_REQUIRED = "Icon is required.";
export const IMAGE_REQUIRED = "Image is required.";
export const QOUTES_REQUIRED = "Quotes name is required.";
export const VIDEO_REQUIRED = "Video is required.";
export const VIDEO_THUM_REQUIRED = "Video thumb is required.";
export const MESSAGE_REQUIRED = "Message is required.";
export const TEXT_COLOR_REQUIRED = "Text color is required";


export const FIRST_NAME_REQUIRED = "First name is required.";
export const LAST_NAME_REQUIRED = "Last name is required.";
export const MAXIMUM_255_CHAR = "Can not be a maximum than 255 char.";
export const MAXIMUM_700_CHAR = "Can not be a maximum than 700 char.";
export const ROLE_ID_REQUIRED = "Role id is required.";
export const LANG_KEY_REQUIRED = "Preferred Language is required";
export const DIRECTORATE_ENGLISH_REQUIRED = "Directorate english  is required.";
export const DIRECTORATE_ARABIC_REQUIRED = "Directorate arabic  is required";
export const GOVERNORATE_ENGLISH_REQUIRED = "Governorate english  is required.";
export const GOVERNORATE_ARABIC_REQUIRED = "Governorate arabic  is required";
export const GOVERNORATE_IMAGE_REQUIRED = "Governorate image is required";
export const VILLAGE_ENGLISH_REQUIRED = "Village english  is required.";
export const VILLAGE_ARABIC_REQUIRED = "Village arabic  is required";
export const AMENITY_ENGLISH_REQUIRED = "Amenity english  is required.";
export const AMENITY_ARABIC_REQUIRED = "Amenity arabic  is required";
export const REASON_REQUIRED = "Reason is required";
export const AGENCY_NAME_REQUIRED = "Agency Name is required";
export const CONTACT_NAME_REQUIRED = "Contact Name is required";
export const AGENCY_EMAIL_REQUIRED ="Agency Email is required";
export const AGENCY_ADDRESS_REQUIRED = "Agency Address is required";

export const QUESTION_REQUIRED = "Question is required";
export const  DESCRIPTION_REQUIRED = "Description is required";


export const PLANNAME_REQUIRED = "Plan name is required";
export const VALIDATION_REQUIRED = "Validation type is required";
export const NUMBER_OF_ACTIVE_PROPERTY_REQUIRED = "Number of active property is required";
export const NUMBER_OF_ACTIVE_AGENT_REQUIRED = "Number of active agent is required";
export const NUMBER_OF_ACTIVE_FEATURED_PROPERTY_REQUIRED = "Number of active featured property is required";
export const NUMBER_OF_3D_VIEW_REQUIRED = "Number of 3d view is required";
export const DASHBOARD_REQUIRED = "Dashboard is required;";
export const PRICE_REQUIRED = "Price is required;"
export const AGENCY_REQUIRED = "Agency is required"
export const NO_NEGATIVE_VALUE = 'Value can not be less than 0'
export const CAPITAL_ENGLISH_REQUIRED = "Capital english  is required.";
export const CAPITAL_ARABIC_REQUIRED = "Capital arabic  is required";

export const ONLY_ENGLISH_CHARACTERS ="Only english characters are allowed!"
export const ONLY_ARABIC_CHARACTERS = "Only arabic characters are allowed!"
export const MAX_FEATURED_PROPERTY_REQUIRED = "MAX featured property is required"
export const MAX_3D_VIEW_REQUIRED = "MAX 3D views required"



// NEW

export const DEVICE_TYPE_REQUIRED = "Device Type is required!"
export const ADDITIONAL_COST_REQUIRED = "Additional cost is required.";
export const MAX_PROPERTY_MUST_BE_GREATER_THAN_FEATURED_PROPERTY = "Max featured property must be greater than and equals the number of active featured properties.";
export const MAX_3D_VIEW_MUST_BE_GREATER_THAN_ACTIVE_3D_VIEW = "Max 3D views must be greater than and equals the number of active 3D views."
export const MAXIMUM_9999 = "Can not be a maximum than 9999.";
export const MAX_ONE_CR = "The price cannot exceed 1 cr.";

export const UNSUPPORTED_FORMAT = "Unsupported Format.";
export const AUTHOR_REQUIRED = "Author is required!";

