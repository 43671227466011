import { MainLayout } from '../components/layouts/MainLayout';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { Profile } from '../pages/Profile/AddProfile';
import { Cms } from '../pages/Cms/routes';
import { Roles_Permissions } from '../pages/roles_permissions/routes';
import { SystemUsers } from "../pages/SystemUsers/routes";
import { Governorate } from "../pages/Governorate/routes"
import { Directorate } from "../pages/Directorate/routes";
import { Village } from "../pages/Village/routes";
import { Agency } from '../pages/Agency/routes';
import { Amenity } from '../pages/Amenity/routes';
import { Faq } from '../pages/Faq/routes';
import { Package } from '../pages/Package/routes';
import { Custom_package } from '../pages/Custom_package/routes';
import { Audit } from '../pages/Audit/routes';
import { PropertyType } from '../pages/PropertyType/Routes';
import { PlatformUsers } from '../pages/PlatformUsers/routes';
import Notification from '../pages/Notification';
import { Error404 } from "../pages/Error404";
import Complaint from '../pages/Complaint';
import { Reports } from '../pages/Reports';
import { Blogs } from '../pages/Blogs/routes';

const basePath = process.env.REACT_APP_BASE_URL;

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */
export const protectedRoutes = [
    {
        path: '/app',
        element: <MainLayout />,
        children: [
            // AMAKEN ROUTES
            { path: '/app', element: <Dashboard /> },
            { path: '/app/notification', element: <Notification /> },
            { path: '/app/dashboard', element: <Dashboard /> },
            { path: 'roles/*', element: <Roles_Permissions /> },
            { path: 'users/*', element: <SystemUsers /> },
            { path: 'governorate/*', element: <Governorate /> },
            { path: 'directorate/*', element: <Directorate /> },
            { path: 'village/*', element: <Village /> },
            { path: 'agency/*', element: <Agency /> },
            { path: 'amenity/*', element: <Amenity /> },
            { path: 'package/*', element: <Package /> },
            { path: 'custom-package/*', element: <Custom_package /> },
            { path: 'property-type/*', element: <PropertyType /> },
            { path: "platform-users/*", element: <PlatformUsers /> },
            { path: "audit/*", element: <Audit /> },

            { path: 'cms/*', element: <Cms /> },
            { path: 'faq/*', element: <Faq /> },
            {path : 'report/*' , element:<Reports/>},
            {path : 'blogs/*' , element:<Blogs/>},
            { path: 'profile', element: <Profile /> },
            {path : '/app/complaint', element : <Complaint/>},
            {
                path: `*`,
                element: <Error404 />,
            },
        ],
    }
];

// Named Routes

export const nameBasedProtectedRoutes =
{
    userDashboard: {
        name: "Dashboard",
        path: `/app`,
    },

    dashboard: {
        name: "Dashboard",
        path: `/app/dashboard`
    },
    notification: {
        name: "Notification",
        path: `/app/notification`
    },
    complaint : {
        name : "Compliant",
        path : `/app/complaint`
    }
} 
